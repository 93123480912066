import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Header } from '../components/Header/Header';
import { graphql } from 'gatsby';
import BlogList from '../components/BlogList/BlogList';

const BlogPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;
    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Blog" />
            <Header />
            <BlogList posts={posts} postsInIndexPage={1000}/>
        </Layout>
    );
}

export default BlogPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      postsInIndexPage
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          cover {
            publicURL
          }
        }
      }
    }
  }
}
`
